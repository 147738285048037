<template>
  <div class="page page-resources" :class="{ 'has-breadcrumbs': displayBreadcrumbs }">
    <div v-if="displayBreadcrumbs" class="page__breadrumbs container">
      <pt-breadcrumbs />
    </div>

    <resources-header
      v-if="!hasHeroBlock"
      :title="currentResource.title"
      :subtitle="currentResource.subtitle"
      :type="currentResource.type"
      :publish-date="currentResource.date_published"
      :time-to-read="currentResource.time_to_read"
      :image="currentResource.image"
      :cta="currentResource.header_cta"
    />

    <resources-buttons :title="currentResource.title" :link="currentResource.url" />
    <div class="page__sections">
      <render-blocks v-if="currentResource.sections" :sections="currentResource.sections" />
    </div>

    <resources-banner
      v-if="currentResource.banner && currentResource.banner.show"
      :title="currentResource.banner.title"
      :subtitle="currentResource.banner.subtitle"
      :cta="currentResource.banner.cta"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useResourcesStore } from '~/stores/resources';
import PtBreadcrumbs from '~/components/global/elements/PtBreadcrumbs.vue';
import ResourcesHeader from '~/components/global/components/resources/ResourcesHeader.vue';
import ResourcesButtons from '~/components/global/components/resources/ResourcesButtons.vue';
import ResourcesBanner from '~/components/global/components/resources/ResourcesBanner.vue';
import RenderBlocks from '~/components/content/RenderBlocks.vue';

const resourceStore = useResourcesStore();
const currentResource = computed(() => resourceStore.getCurrentResource);

const displayBreadcrumbs = computed(() => {
  return !(!currentResource.value || currentResource.value.hide_breadcrumbs);
});

const sections = computed(() => {
  return Array.isArray(currentResource.value.sections) ? currentResource.value.sections : [];
});

const hasHeroBlock = computed(() => {
  if (!sections.value.length) {
    return false;
  }
  return ['block_hero', 'block_hero_cta', 'block_hero_simple'].includes(sections.value[0].type);
});
</script>
