<template>
  <div v-if="title || subtitle || (cta && cta.link)" class="resources-banner">
    <div class="container">
      <div class="resources-banner__inner">
        <h1 class="title is-1">{{ title }}</h1>
        <p v-if="subtitle">{{ subtitle }}</p>
        <pt-button v-if="cta && cta.link" v-bind="getButtonBindings(cta)">
          {{ cta.label }}
        </pt-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useButtons } from '~/composables/buttons';
import PtButton from '~/components/global/elements/PtButton.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  cta: {
    type: Object,
    default: undefined,
  },
});

const { getButtonBindings } = useButtons();
</script>
