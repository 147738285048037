<template>
  <div class="resources-buttons container">
    <div
      class="resources-buttons__list"
      :class="{
        'with-bg': withBg,
      }"
    >
      <div class="resources-buttons__item is-print-button">
        <pt-icon class="resources-buttons__icon" name="print" :size="24" />
        <button class="link-primary is-3" @click="showPrint">
          <span class="link-primary__label"> Print </span>
        </button>
      </div>
      <div class="resources-buttons__item">
        <pt-icon class="resources-buttons__icon" name="mail" :size="24" />
        <a :href="emailLink" class="link-primary is-3">
          <span class="link-primary__label"> Email </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PtIcon from '~/components/global/elements/PtIcon.vue';

const props = defineProps({
  withBg: Boolean,
  title: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
});

const emailLink = computed(() => {
  const subject = `subject=Public Trust Resource: ${props.title}`;
  const body = `${useRuntimeConfig().public.baseUrl}${props.link}`;
  return `mailto:?${subject}&body=${body}`;
});

const showPrint = () => {
  window.print();
};
</script>
