<template>
  <header class="container has-marg-top-xl has-marg-bottom-xl">
    <div class="resources-header" :class="getBgClass()">
      <div class="columns">
        <div class="resources-header__info-wrap column is-12-mobile is-8-tablet is-7-desktop">
          <div class="resources-header__info">
            <div class="resources-header__info-top">
              <span v-if="type" class="resources-header__info-top-text">{{ type }}</span>
              <span v-if="publishDate" class="resources-header__info-top-text">{{ dateFormat }}</span>
              <span v-if="timeToRead" class="resources-header__info-top-text">{{ timeToRead }}</span>
            </div>
            <h1 class="title is-1">
              {{ title }}
            </h1>
            <p v-if="subtitle">
              {{ subtitle }}
            </p>
            <pt-button v-if="cta && cta.link" v-bind="buttonBindings">
              {{ cta.label }}
            </pt-button>
          </div>
        </div>

        <div v-if="image" class="resources-header__image column is-12-mobile is-4-tablet is-5-desktop">
          <picture-basic v-bind="imageBindings" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getSelectValue } from '@/utils/blocks';
import { getPictureBindings } from '~/utils/images';
import type { NullableObjectImage } from '~/utils/images';
import PtButton from '~/components/global/elements/PtButton.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { type ButtonBindings, useButtons } from '~/composables/buttons';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  background: {
    type: Object,
    default: undefined,
  },
  subtitle: {
    type: String,
    default: '',
  },
  image: {
    type: Object as () => NullableObjectImage,
    default: undefined,
  },
  type: {
    type: String,
    default: '',
  },
  timeToRead: {
    type: String,
    default: '',
  },
  publishDate: {
    type: String,
    default: '',
  },
  cta: {
    type: Object as () => ButtonBindings,
    default: undefined,
  },
});

const { getButtonBindings } = useButtons();

const imageBindings = computed(() => getPictureBindings(props.image, { contain: false }));
const buttonBindings = computed(() => getButtonBindings(props.cta));

const dateFormat = computed(() => {
  if (!props.publishDate) {
    return null;
  }

  const date = new Date(props.publishDate);
  return date.toLocaleDateString('en-NZ', { year: 'numeric', month: 'short' });
});

const getBgClass = () => {
  const background = getSelectValue(props.background, 'value', 'peach');
  return [`has-bg-${background}`];
};
</script>
